<template>
  <el-dialog
    title="修改用户类型"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="用户" prop="realName">
        <span>{{ modalData.realName }}</span>
      </el-form-item>

      <el-collapse accordion class="collapse">
        <el-collapse-item
          v-for="(item, index) in categoryList"
          :title="
            item.name +
            '-首推奖励：' +
            item.firstReward +
            '     续订奖励：' +
            item.renewalReward
          "
          :name="item.id"
        >
          <div>
            <el-form-item label="首次推广奖励" prop="firstReward">
              <el-input
                type="text"
                placeholder="首次推广奖励"
                v-model="item.firstReward" 
              />
            </el-form-item>

            <el-form-item label="续订奖励" prop="renewalReward">
              <el-input
                type="text"
                placeholder="月卡奖励金"
                v-model="item.renewalReward"
              />
            </el-form-item>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { fetchProductCategory } from "@/api/common";
import { fetchPuserInfo } from "@/api/batteryMan";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      categoryList: [],
      categoryType: [],
      ruleValidate: {
        isPusher: [
          {
            required: true,
            message: "用户推广类型不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    this.getProductCategory();
  },

  methods: {
    getProductCategory() {
      fetchProductCategory().then((res) => {
        let list = res.data.data || [];
        this.categoryType = [];
        this.categoryList = [];

        list.map((item) => {
          this.categoryList.push({
            goodsTypeId: item.id,
            name: item.name,
            firstReward: 0,
            renewalReward: 0,
          });
        });
       
        this.categoryType = this.deepClone(this.categoryList)
      });
    },

    showModal(modalData) {
      this.isShow = true;
      this.getDetail(modalData);
      if (this.$refs[this.formName]) {
        // 清除校验
        this.$nextTick(() => {
          this.$refs[this.formName].clearValidate();
        });
      }
    },

    getDetail(modalData) {
      fetchPuserInfo({
        userId: modalData.userId,
      }).then((res) => {
        let list = res.data.data;
        if (list.length > 0) {
          this.categoryList = res.data.data;
          this.categoryList.map((dataItem) => {
            dataItem.firstReward = dataItem.firstReward / 100;
            dataItem.renewalReward = dataItem.renewalReward / 100;
            this.categoryType.map((item) => {
              if (dataItem.goodsTypeId === item.goodsTypeId) {
                dataItem.name = item.name;
              }
            });
          });
        } else {
          this.categoryList = this.deepClone(this.categoryType);
        }
      });
    },

    submit() {
      let obj = {
        userId: this.modalData.userId,
        zzRewardRuleList: this.deepClone(this.categoryList),
      };

      obj.zzRewardRuleList.map((dataItem) => {
        dataItem.firstReward = dataItem.firstReward * 100;
        dataItem.renewalReward = dataItem.renewalReward * 100;
      });

      this.validateForm().then((res) => {
        this.$emit("submit", obj);
      });
    },
  },
};
</script>

<style scoped>
.el-collapse {
  border: 0 !important;
  margin-bottom: 10px !important;
}

.el-collapse-item {
  border: 1px solid #e1e1e1 !important;
  padding: 10px !important;
  border-radius: 5px;
  margin: 10px 0;
}

.collapse /deep/ .el-collapse-item__header,
.el-collapse-item__wrap {
  border: 0 !important;
}
</style>