<template>
  <el-dialog
    title="增加推广员"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="用户" prop="userId">
        <span v-if="modalType == 'edit'">{{ modalData.realName }}</span>
        <eb-filter-user-list v-else v-model="modalData.userId"></eb-filter-user-list>
      </el-form-item>

      <el-form-item label="推广类型" prop="isPusher">
        <el-select placeholder="请选择用户类型" v-model="modalData.isPusher">
          <el-option
            v-for="(item, index) in this.const.PUSHER_USER_TYPE"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          placeholder="请输入备注"
          v-model="modalData.remark"
          :autosize="{ minRows: 4, maxRows: 10 }"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        isPusher: [
          {
            required: true,
            message: "用户推广类型不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
  },

  methods: {

    submit() {
      let obj = {
        userId: this.modalData.userId,
        isPusher: this.modalData.isPusher,
        remark: this.modalData.remark
      };
      this.validateForm().then((res) => {
        this.$emit("submit", obj);
      });
    },
  },
};
</script>