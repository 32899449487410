<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="手机号码"
          v-model="query.phone"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="用户姓名"
          v-model="query.realName"
          @change="getList(1)"
        ></el-input>
      </el-col>

       <el-col :span="4">
        <el-select
          placeholder="推广员查询"
          v-model="query.isPusher"
          clearable
          @change="getList(1)"
        >
          <el-option
            v-for="(item, index) in this.const.PUSHER_USER_TYPE"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-button class="but" type="primary" @click="showAddPusherModal"
        >添加推广员</el-button
      >
    </div>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="id" prop="id">
        <template #default="scope">
          {{scope.row.id}}
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="realName"></el-table-column>
      <el-table-column label="电话" prop="phone"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showUpdatePusher(scope.row)"
          >
            修改奖励
          </span>

          <span
            class="option option-primary"
            @click="showEditPusherType(scope.row)"
          >
            修改身份
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditUserPusherTypeModal
      :modalData="modalData"
      :ref="MODAL_KEY.EDIT_PUSHER_MODAL"
      @submit="submitUserPusherType"
    ></EditUserPusherTypeModal>

    <EditModel
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.ADD_PUSHER_MODAL"
      @submit="submitAddPusher"
    ></EditModel>
  </div>
</template>
 
<script>
import {
  fetchPusherList,
  updateUserPusherAwardRule,
  updateUserPusherType,
} from "@/api/batteryMan";
import EditUserPusherTypeModal from "./components/EditUserPusherTypeModal";
import EditModel from "./components/EditModel";
import { mapState } from "vuex";
export default {
  components: { EditUserPusherTypeModal, EditModel },
  computed: mapState({}),

  data() {
    return {
      MODAL_KEY: {
        EDIT_PUSHER_MODAL: "EDIT_PUSHER_MODAL",
        ADD_PUSHER_MODAL: "ADD_PUSHER_MODAL",
      },
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        isPusher: 2
      },

      modalData: {},
      modalType: "add",
      total: 0,
    };
  },
  methods: {
    showAddPusherModal() {
      this.modalType = "add";
      this.modalData = {};
      this.$refs[this.MODAL_KEY.ADD_PUSHER_MODAL].showModal();
    },

    showEditPusherType(data) {
      this.modalType = "edit";
      this.modalData = this.deepClone(data);
      this.$refs[this.MODAL_KEY.ADD_PUSHER_MODAL].showModal();
    },

    // 增加推广员
    submitAddPusher(data) {
      updateUserPusherType({
        userId: data.userId,
        isPusher: data.isPusher,
        description: data.remark,
      }).then((res) => {
        this.$refs[this.MODAL_KEY.ADD_PUSHER_MODAL].closeModal();
        this.getList();
      });
    },

    // 修改用户推广类型
    submitUserPusherType(data) {
      updateUserPusherAwardRule( data).then((res) => {
        this.$refs[this.MODAL_KEY.EDIT_PUSHER_MODAL].closeModal();
        this.getList();
      });
    },

    showUpdatePusher(data) {
      this.modalData = {
        userId: data.id,
        realName: data.realName,
      };
      this.$refs[this.MODAL_KEY.EDIT_PUSHER_MODAL].showModal(this.modalData);
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchPusherList({ ...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records || [];
          this.total = res.data.data.total;
        }
      );
    },

    resetPage() {
      this.dataSource = [];
      this.pages = {
        pageIndex: 1,
        pageSize: 10,
      };
      this.agentMap = {};
      this.query = {
        isPusher: 2
      };
    },
  },

  beforeRouteEnter(to, from, next) {
    if (from.fullPath.indexOf("detail") > -1) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.dataSource.length === 0) {
      this.resetPage();
      this.getList(1);
    }
  },
};
</script>
